/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState, useCallback } from "react";
import Slider from "react-slick";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import Pageheading from "../../components/PageHeading/Pageheading";
import { RiHome2Line } from "react-icons/ri";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BiRightArrowAlt } from "react-icons/bi";
import "./Category.css";
import CustomPlaybutton from "../../components/Buttons/CustomPlaybutton";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import bg from "../../assets/bg_image/banner.png";
import Page from "../../components/CatList/Page";
import SectionHeading from "../../components/SectionHeading/SectionHeading";
import Button from "../../components/Buttons/Button";
import MobileSlider from "../../components/CostomSlider/MobileSlider";
import Aos from "aos";
const Longformat = ({ pageHeading }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);

  const images = [
    {
      id: 1,
      videoId: 290693752,
      url: "https://vimeo.com/290693752",
      thumbnail: "/category/poster_1.png",
      // hyundai
      src: "5.jpeg",
      title: "images/753845722_karmma_calling.png",
      content: "Avatar: The Way of Water",
      year: "2024",
      season: "1 Season",
      language: "7 Languages",
      rating: "U/A 16+",
      description:
        "Jake Sully and Neytiri fight to protect their family from new threats.",
      genres: ["Thriller", "Adventure"],
    },
    {
      id: 2,
      videoId: 291681025,
      url: "https://vimeo.com/291681025",
      // vadilal
      thumbnail: "/category/poster_2.png",
      src: "2.jpeg",
      title: "images/753845722_karmma_calling.png",
      content: "The Witcher: Blood Origin",
      year: "2024",
      season: "1 Season",
      language: "7 Languages",
      rating: "U/A 16+",
      description:
        "Explore the origins of the Witcher universe in this epic tale of magic and monsters.",
      genres: ["Drama", "Action"],
    },
    {
      id: 3,
      videoId: 333499420,
      url: "https://vimeo.com/333499420",
      thumbnail: "/category/poster_1.png",
      src: "3.jpeg",
      title: "images/753845722_karmma_calling.png",
      content: "Spider-Man: A New Beginning",
      year: "2024",
      season: "1 Season",
      language: "7 Languages",
      rating: "U/A 12+",
      description:
        "Peter Parker's journey continues as he takes on new villains and navigates the challenges.",
      genres: ["Action", "Adventure"],
    },
    {
      id: 4,
      videoId: 293940724,
      url: "https://vimeo.com/538105209",
      thumbnail: "/category/poster_2.png",
      // hunk
      src: "1.jpeg",
      title: "images/753845722_karmma_calling.png",
      content: "New Release",
      year: "2024",
      season: "1 Season",
      language: "7 Languages",
      rating: "U/A 16+",
      description:
        "Nothing matters when the world is at your feet. But can anyone really cheat one's Karma?",
      genres: ["Drama", "Thriller"],
    },
    {
      id: 5,
      videoId: 330036411,
      url: "https://vimeo.com/278793691",
      // godrej
      thumbnail: "/category/poster_1.png",
      src: "5.jpeg",
      title: "images/753845722_karmma_calling.png",
      content: "Karmma Calling",
      year: "2024",
      season: "1 Season",
      language: "7 Languages",
      rating: "U/A 16+",
      description:
        "Nothing matters when the world is at your feet. But can anyone really cheat one's Karma?",
      genres: ["Thriller", "Adventure"],
    },
    {
      id: 6,
      videoId: 276703567,
      url: "https://vimeo.com/276703567",
      // vadilal friends
      thumbnail: "/category/poster_2.png",
      src: "6.jpeg",
      title: "images/753845722_karmma_calling.png",
      content: "The Batman: Dark Knight Rises",
      year: "2024",
      season: "1 Season",
      language: "7 Languages",
      rating: "U/A 16+",
      description:
        "Batman faces his greatest challenge yet as he takes on a new adversary threatening Gotham.",
      genres: ["Thriller", "Adventure"],
    },
    // Add more objects as needed
  ];

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="custom-next-arrow absolute right-[-2vw] text-[2vw]  top-1/2 transform -translate-y-1/2 z-10 cursor-pointer opacity-80"
        onClick={onClick}
      >
        <FiChevronRight color="#ffff" /> {/* Custom right icon */}
      </div>
    );
  };

  // Custom Previous Arrow
  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="custom-prev-arrow absolute left-[-2vw] text-[2vw]   top-1/2 transform -translate-y-1/2 z-10 cursor-pointer  opacity-80"
        onClick={onClick}
      >
        <FiChevronLeft color="#ffff" />
      </div>
    );
  };

  const [activeIndex, setActiveIndex] = useState(1); // Start with the middle image
  const navigate = useNavigate();
  const location = useLocation();

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
     // centerMode: true, // Center the active slide
    autoplay: false,
    autoplaySpeed: 4000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    // Enable autoplay
    // Set the autoplay speed to 2 seconds (2000ms)
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: (current, next) => setActiveIndex(next), // Update active index on slide change
  };

  // Check if the current path is a nested route
  const isDetailPage = location.pathname.includes("/category/details");
  const isSubCategoryPage = location.pathname.includes("/category/");
  // const isSubCategoryPage = location.pathname.includes("/upcoming/");
  // const currentVideoUrl = images[activeIndex].url;
  const videothumbnail = images[activeIndex].thumbnail;
  const videoId = images[activeIndex].videoId;
  // const title = images[activeIndex].title;

  const handleWatchTrailer = () => {
    navigate(`/watch/${videoId}`);
  };

  const handleVideoLoad = useCallback(() => {
    setIsVideoLoaded(true);
  }, []);

  useEffect(() => {
    if (playVideo) {
      const timer = setTimeout(() => {
        setPlayVideo(false);
      }, 10000); // Stop video after 10 seconds

      return () => clearTimeout(timer);
    }
  }, [playVideo]);

  const handleVideoAreaClick = (e) => {
    
    if (
      e.target.closest(".slider") ||
      e.target.closest(".watch-trailer-button")
    ) {
      return;
    }

    navigate(`/short-documentaries/details/${images[activeIndex].videoId}`);
  };

  const handleViewAll = () => {
    let Category = "";
    let categoryId;

    if (location.pathname.includes("/short-documentaries")) {
      Category = "short-documentaries";
      categoryId = 1;
    } else if (location.pathname.includes("/commercials")) {
      Category = "commercials";
      categoryId = 2;
    } else if (location.pathname.includes("/long-format")) {
      Category = "long-format";
      categoryId = 3;
    }

    if (Category) {
      navigate(`/${Category}/${categoryId}/all`);
    }
  };

  const handleViewUpcoming = () => {
    let Category = "";
    let categoryId;

    if (location.pathname.includes("/short-documentaries")) {
      Category = "short-documentaries";
      categoryId = 1;
    } else if (location.pathname.includes("/commercials")) {
      Category = "commercials";
      categoryId = 2;
    } else if (location.pathname.includes("/long-format")) {
      Category = "long-format";
      categoryId = 3;
    }

    if (Category) {
      navigate(`/${Category}/${categoryId}/upcoming`);
    }
  };

  const handleViewlatestRelease = () => {
    let Category = "";
    let categoryId;

    if (location.pathname.includes("/short-documentaries")) {
      Category = "short-documentaries";
      categoryId = 1;
    } else if (location.pathname.includes("/commercials")) {
      Category = "commercials";
      categoryId = 2;
    } else if (location.pathname.includes("/long-format")) {
      Category = "long-format";
      categoryId = 3;
    }

    if (Category) {
      navigate(`/${Category}/${categoryId}/latest-release`);
    }
  };
 

  useEffect(() => {
    Aos.init({ 
      easing: 'ease-in', 
      duration: 1500,
      once: false,
     
    });
  }, []);

  return (
    <div className="max-w-screen overflow-x-hidden mx-auto">
      {!isDetailPage && !isSubCategoryPage && (
        <div
          className="relative w-full  m-0 p-0  "
          onMouseEnter={() => {
            setIsHovered(true);
            setPlayVideo(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
            setPlayVideo(false);
          }}
        >
          {/* Video Background */}

          <div
            className="absolute top-0 left-0 w-full h-[92vh] lg:h-[93dvh]  cursor-pointer !overflow-hidden bg-cover bg-no-repeat bg-center"
            onClick={handleVideoAreaClick}
            style={{
              zIndex: 1,
              backgroundImage: `url(${
                window.innerWidth < 768 ? bg : videothumbnail
              })`,
            }}
          >
            {isHovered && (
              <div className="vimeo-full-width">
                {/* Local video for mobile devices */}
                <video
                  src="/video/videoplayback.mp4" // Local video from public folder
                  className={`mobile-video w-full h-full absolute top-0 left-0  transition-opacity duration-500 ${
                    isHovered || isVideoLoaded ? "opacity-100" : "opacity-0"
                  }`}
                  autoPlay={isHovered}
                  loop
                  muted
                  controls={false}
                  onCanPlay={handleVideoLoad}
                  onError={() =>
                    console.error("Failed to load the local video.")
                  }
                  style={{ pointerEvents: "none" }}
                />

                {/* Vimeo iframe for larger screens */}
                <iframe
                  src={`https://player.vimeo.com/video/${videoId}?playsinline=0&autoplay=${
                    isHovered ? 1 : 0
                  }&loop=1&muted=1&controls=0`}
                  className={`desktop-video w-full h-full absolute top-0 left-0  transition-opacity duration-500 ${
                    isHovered || isVideoLoaded ? "opacity-100" : "opacity-0"
                  }`}
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  onLoad={handleVideoLoad}
                  onError={() =>
                    console.error("Failed to load the Vimeo video.")
                  }
                  style={{ pointerEvents: "none" }}
                ></iframe>
              </div>
            )}
            {!isHovered && (
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                <CustomPlaybutton
                  onClick={() => navigate(`/watch/${videoId}`)}
                />
              </div>
            )}
          </div>

          {/* overlay */}
          <div className="grid grid-cols-1  lg:grid-cols-2 w-full h-[92vh] lg:h-[93dvh]  ">
            <div className="flex flex-col justify-between    h-full lg:pl-[5.625vw] py-[10vh] lg:py-0   lg:pt-[10.29vw] pb-[3vw] lg:pb-[7.57vh]">
              <div className=" ml-4 lg:ml-0 pt-[4vh] lg:pt-0 flex items-center z-10">
                <Pageheading pageHeading={pageHeading} icon={<RiHome2Line />} />
              </div>

              <div
                className={` relative z-30  flex flex-col items-center lg:items-start justify-center lg:justify-start lg:gap-y-[1.77vh] gap-y-[1.7vh] px-[3.27vw] lg:px-[1vw] pt-[8vh] pb-[5vw] lg:pt-[4vh] lg:pb-0 
              `} 
              data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-offset="150"
              >
                <div className="lg:w-[22.73vw] lg:h-[14.17vh] w-[50.67vw]">
                  <img
                    src={`${images[activeIndex].title}`}
                    alt=""
                    className="h-full w-full object-fill"
                  />
                </div>
                <div className="flex flex-wrap items-center lg:pt-[1.5vh]  text-[#22AD86] text-left lg:text-[1.25vw] text-[4vw] font-semibold font-montserrat  leading-[3.15vh]">
                  {images[activeIndex].content}
                </div>
                <div className="flex flex-wrap items-center space-x-2 text-white text-left lg:text-[1.25vw] text-[4vw] font-semibold font-montserrat leading-[3.15vh]">
                  <div>{images[activeIndex].year}</div>
                  <div className="w-2 h-2 rounded-full bg-white opacity-50"></div>
                  <div>{images[activeIndex].season}</div>
                  <div className="w-2 h-2 rounded-full bg-white opacity-50"></div>
                  <div>{images[activeIndex].language}</div>
                  <div className="w-2 h-2 rounded-full bg-white opacity-50"></div>
                  <div className="text-white bg-[#36c31f69] rounded-[4px] md:p-[2px] px-[3px]">
                    <span className="text-white whitespace-pre-line lg:text-[1.25vw] text-[4vw] leading-none brightness-200">
                      {images[activeIndex].rating}
                    </span>
                  </div>
                </div>
                <p className="whitespace-pre-line font-montserrat text-white opacity-100 font-normal md:text-[1.25vw] text-16  lg:w-[80%] hidden lg:block  ">
                  {images[activeIndex].description}
                </p>
                <div className="lg:flex items-center space-x-3 lg:pt-[1.99vh] text-white lg:text-[1.25vw] text-16 font-semibold font-montserrat lg:leading-[3.15vh] opacity-100  hidden ">
                  {images[activeIndex].genres.map((genre, index) => (
                    <React.Fragment key={index}>
                      <span>{genre}</span>
                      {index < images[activeIndex].genres - 1 && (
                        <div className="w-[2px] h-6 bg-white"></div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
                <div className="border-[1px] border-white lg:px-[1.30vw] py-[2vh] px-[5vw] p-[2vw] lg:mt-[3.09vh] rounded-full lg:rounded-[3.40vw]  relative overflow-hidden group">
                  <button
                    className="flex items-center  gap-x-3 text-white uppercase font-montserrat leading-0 text-[4vw] lg:text-[1.25vw] font-semibold opacity-100 relative z-10"
                    onClick={handleWatchTrailer}
                  >
                    Watch Trailer
                    <div className="flex items-center justify-center bg-[#4AB66C] rounded-full p-[2px] transition-colors duration-300 ease-in-out group-hover:bg-white">
                      <BiRightArrowAlt className="text-white w-[6.5vw] md:w-[2.60417vw] h-auto transition-transform duration-300 ease-in-out group-hover:text-[#22AD86] -rotate-45 group-hover:rotate-0" />
                    </div>
                  </button>
                  <div className="absolute inset-0 bg-custom-vertical-gradient transform translate-x-full group-hover:translate-x-0 transition-transform duration-500 ease-in-out"></div>
                </div>
              </div>
            </div>

            {/* Image Slider */}
            <div className="hidden lg:flex items-end justify-center lg:pb-[7vh] lg:px-[0.625vw]"
          
            >
              <div className="hidden md:block  w-[60%] z-20 "
              data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-offset="150"
              >
                <Slider {...settings}>
                  {images.map((img, index) => (
                    <div
                      key={index}
                      onClick={() => setActiveIndex(index)}
                      className={`relative overflow-visible md:w-[8vw] md:h-[13.80vh]  ease-in transition-transform duration-500 !cursor-pointer ${
                        activeIndex === index
                          ? " border-2 md:h-[15vh] border-solid border-[#71BF52]"
                          : "border-none scale-90 hover:scale-[1.05] transition-all duration-500"
                      }`}
                    >
                      <img
                        src={`/images/${img.src}`}
                        alt={`image-${img.id}`}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      )}

      <Outlet />
      {!isDetailPage && !isSubCategoryPage && (
        <div className="w-full  h-full">
          <div className="flex md:px-[6.77vw] items-center justify-between pb-0 pt-[60px] md:pt-[8.70vh]  ">
            <div className="pl-[3vw] pr-[40vw] lg:pl-0 md:pr-0">
              <SectionHeading sectionheading={"ALL Commercials"} onClick={()=>navigate("/1/all")}/>
            </div>
            <span className="hidden md:block">
              <Button buttonText={"VIEW ALL"} onClick={handleViewAll} />
            </span>
          </div>
          <div className="hidden md:block">
            <Page />
          </div>
          <div className=" lg:hidden pt-[40px] block relative ">
            <MobileSlider />
          </div>
        </div>
      )}
      {/* {!isDetailPage && !isSubCategoryPage && <Upcoming />} */}
      {/* {!isDetailPage && !isSubCategoryPage && (
        <div className="w-full  h-full">
          <div className="flex md:px-[6.77vw] items-center justify-between pb-0 pt-[60px] md:pt-[8.7vh] ">
            <div className="pl-[3vw] pr-[40vw] lg:pl-0 md:pr-0">
              <SectionHeading sectionheading={"Upcoming"} onClick={()=>navigate("/2/upcoming")}  />
            </div>
            <span className="hidden md:block">
              <Button buttonText={"VIEW ALL"} onClick={handleViewUpcoming} />
            </span>
          </div>
          <div className="hidden md:block ">
            <Page />
          </div>
          <div className=" lg:hidden pt-[40px] block relative">
            <MobileSlider />
          </div>
        </div>
      )} */}

      {/* {!isDetailPage && !isSubCategoryPage && <Latestrelease />} */}
      {/* {!isDetailPage && !isSubCategoryPage && (
        <div className="w-full  h-full">
          <div className="flex md:px-[6.77vw] items-center justify-between pb-0 pt-[60px] md:pt-[8.7vh] ">
            <div className="pl-[3vw] pr-[40vw] lg:pl-0 md:pr-0">
              <SectionHeading sectionheading={"Latest release"} onClick={()=>navigate("/3/latest-release")}  />
            </div>
            <span className="hidden md:block">
              <Button
                buttonText={"VIEW ALL"}
                onClick={handleViewlatestRelease}
              />
            </span>
          </div>
          <div className="hidden md:block ">
            <Page customCss={"md:!pb-[7vh]"}/>
          </div>
          <div className=" lg:hidden pt-[40px] block relative">
            <MobileSlider />
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Longformat;
