/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import img1 from '../../assets/AboutUs/1.png';
import img2 from '../../assets/AboutUs/2.png';
import img3 from '../../assets/AboutUs/3.png';
import img4 from '../../assets/AboutUs/4.png'; 

const images = [img1, img2, img3, img4, img1, img2, img3, img4];

export function Award() {
  return (
    <div className="md:pr-[4.58vw] grid grid-cols-1 md:grid-cols-4 ">
      {images.map((image, index) => (
        <div key={index} className="flex justify-center items-center pb-[4.44vh] md:pb-[4.44vh]">
          <div className="w-[91vw] md:w-[20.68vw] flex flex-col bg-cover bg-center overflow-hidden relative">
            <img
              src={image}
              alt={`Image ${index + 1}`}
              className="w-full h-full object-fill filter grayscale hover:grayscale-0 transition duration-300 ease-in-out"
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default Award;
